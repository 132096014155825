import { axios } from 'api/request'

/**
 * @description 登录
 */
export function loginSys(parameter) {
  return axios({
    url: '/user/webLogin',
    method: 'post',
    data: parameter
  });
}

/**
 * @description 用户修改密码
 */
export function updatePwd(parameter) {
  return axios({
    url: '/user/updatePwd',
    method: 'post',
    data: parameter
  });
}

/**
 * @description 获取机构列表
 */
export function getOrgList(parameter) {
  return axios({
    url: '/org/webList',
    method: 'post',
    data: parameter
  });
}

/**
 * @description 添加机构
 */
export function addOrg(parameter) {
  return axios({
    url: '/org/webAdd',
    method: 'post',
    data: parameter
  });
}

/**
 * @description 修改机构
 */
export function editOrg(parameter) {
  return axios({
    url: '/org/webEdit',
    method: 'post',
    data: parameter
  });
}

/**
 * @description 设置试用期限
 */
export function setDeadline(parameter) {
  return axios({
    url: '/org/setDeadline',
    method: 'post',
    data: parameter
  });
}

/**
 * @description 冻结机构
 */
export function frozenOrg(parameter) {
  return axios({
    url: '/org/frozen',
    method: 'post',
    data: parameter
  });
}

/**
 * @description 获取检查表列表
 */
export function getModelList(parameter) {
  return axios({
    url: '/exportModel/page',
    method: 'get',
    params: parameter
  });
}

/**
 * @description 修改检查表名称
 */
export function editModel(parameter) {
  return axios({
    url: '/exportModel/editItem',
    method: 'post',
    data: parameter
  });
}

/**
 * @description 检查表冻结
 */
export function editStatus(parameter) {
  return axios({
    url: '/exportModel/editStatus',
    method: 'post',
    data: parameter
  });
}

/**
 * @description 检查表详情
 */
export function getModelDetail(parameter) {
  return axios({
    url: '/exportModel/detail',
    method: 'get',
    params: parameter
  });
}

/**
 * @description H5页面所需参数
 */
export function getH5Data(parameter) {
  return axios({
    url: '/user/getH5Data',
    method: 'get',
    params: parameter
  });
}


